<template>
  <v-card flat>
    <v-form
      ref="form"
    >
      <v-row>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="form.patientName"
            :label="$vuetify.lang.t('$vuetify.patient.name')"
            :rules="rules.patientName"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-select
            v-model="form.gender"
            :label="$vuetify.lang.t('$vuetify.common.gender')"
            :items="genderItems"
            :rules="rules.gender"
            item-text="name"
            item-value="value"
          ></v-select>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="form.credentialNum"
            :label="$vuetify.lang.t('$vuetify.common.identityCard')"
            :rules="rules.credentialNum"
          ></v-text-field>
        </v-col>


        <v-col
          cols="4"
        >
          <v-text-field
            v-model="form.phone"
            :label="$vuetify.lang.t('$vuetify.common.phone')"
            :rules="rules.phone"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-text-field
            v-model="form.national"
            :label="$vuetify.lang.t('$vuetify.patient.national')"
            :rules="rules.national"
          ></v-text-field>
        </v-col>

        <v-col
          cols="4"
        >
          <v-select
            v-model="form.disabilityStatus"
            :label="$vuetify.lang.t('$vuetify.patient.disabilityStatus')"
            :items="disabilityStatusItems"
            :rules="rules.disabilityStatus"
            item-text="name"
            item-value="value"
            clearable
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="2"
        >
          <v-select
            v-model="form.province"
            :label="$vuetify.lang.t('$vuetify.region.province')"
            :items="provinceItems"
            :rules="rules.province"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleProvinceChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.city"
            :label="$vuetify.lang.t('$vuetify.region.city')"
            :items="cityItems"
            :rules="rules.city"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleCityChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.county"
            :label="$vuetify.lang.t('$vuetify.region.county')"
            :items="countyItems"
            :rules="rules.county"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleCountyChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.town"
            :label="$vuetify.lang.t('$vuetify.region.town')"
            :items="townItems"
            :rules="rules.town"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleTownChange"
          ></v-select>
        </v-col>

        <v-col
          cols="4"
        >
          <v-select
            v-model="form.village"
            :label="$vuetify.lang.t('$vuetify.region.village')"
            :items="villageItems"
            :rules="rules.village"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleVillageChange"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>

        <v-col
          cols="12"
        >
          <v-text-field
            v-model="form.diseases"
            :label="$vuetify.lang.t('$vuetify.patient.diseases')"
            clearable
          ></v-text-field>
        </v-col>

      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { phone, idCard } from '@/utils/validators';

  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        birthdayMenu: false,

        genderItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.gender.male'),
            value: 'MALE'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.gender.female'),
            value: 'FEMALE'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.gender.unknown'),
            value: 'UNKNOWN'
          }
        ],

        disabilityStatusItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.disabilityStatus.disability'),
            value: 'DISABILITY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.disabilityStatus.halfDisability'),
            value: 'HALF_DISABILITY'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.disabilityStatus.noDisability'),
            value: 'NO_DISABILITY'
          }
        ],
        provinceItems: [],
        cityItems: [],
        countyItems: [],
        townItems: [],
        villageItems: [],

        form: {
          id: null,
          patientName: null,
          gender: null,
          phone: null,
          credentialNum: null,
          national: null,
          disabilityStatus: null,
          diseases: null,
          province: null,
          city: null,
          county: null,
          town: null,
          village: null,
          credentialType: 'I'
        },

        rules: {
          patientName: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.patient.name')])
          ],
          gender: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.common.gender')])
          ],
          phone: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.phone')]),
            (v) =>
              phone.test(v) || this.$vuetify.lang.t('$vuetify.rule.format', [this.$vuetify.lang.t('$vuetify.common.phone')])
          ],
          credentialNum: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.identityCard')]),
            (v) =>
              idCard.test(v) || this.$vuetify.lang.t('$vuetify.rule.format', [this.$vuetify.lang.t('$vuetify.common.identityCard')])
          ],
          national: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.patient.national')])
          ],
          disabilityStatus: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.patient.disabilityStatus')])
          ],
          province: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.province')])
          ],
          city: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.city')])
          ],
          county: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.county')])
          ],
          town: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.town')])
          ],
          village: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.village')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        regions: 'region/regions'
      })
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        regionQueryAll: 'region/queryAll'
      }),

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
          this.getRegion({parentId: ''}).then( () => {
            return this.getRegion({  parentId: this.form.province }, 'cityItems')
          }).then( () => {
            return this.getRegion({  parentId: this.form.city }, 'countyItems')
          }).then( () => {
            return this.getRegion({  parentId: this.form.county }, 'townItems')
          }).then( () => {
            return this.getRegion({  parentId: this.form.town }, 'villageItems')
          })
        } else {
          this.resetFrom()
          this.getRegion({parentId: ''})
        }
      },

      getRegion (params = {}, target = 'provinceItems') {
        return new Promise((resolve, reject) => {
          this.regionQueryAll(params).then(() => {
            this[target] = this.regions
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },

      handleProvinceChange (id) {
        this.cityItems = []
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.city = null
        this.form.county = null
        this.form.town = null
        this.form.village = null
        if (id) {
          this.getRegion({  parentId: id }, 'cityItems')
        }
      },

      handleCityChange (id) {
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.county = null
        this.form.town = null
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'countyItems')
        }
      },

      handleCountyChange (id) {

        this.townItems = []
        this.villageItems = []
        this.form.town = null
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'townItems')
        }
      },

      handleTownChange (id) {
        this.villageItems = []
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'villageItems')
        }
      },

      handleVillageChange () {

      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      }
    }
  }
</script>
